import React from "react";
import { Link } from "react-router-dom";
import * as images from "../../src/utilities/images";

const Sidebar = () => {
  const pathName = window.location.pathname;

  return (
    <div>
      <aside className=" sidebarSil main-sidebar sideBar-bg sidebar-dark-primary elevation-4 main">
        <Link to="/dashboard" className="brand-link">
          <img
            src={images.brandLogo}
            className="brand_logo"
            alt="Brand Logo"
            style={{ width: "100px" }}
          />
        </Link>
        <div className="sidebar">
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="true"
            >
              <li className="nav-item sideheading">
                <Link
                  to="/dashboard"
                  className={
                    ["/dashboard"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-th"></i>
                  <p>Dashboard</p>
                </Link>
              </li>

              <li className="nav-item sideheading">
                <Link
                  to="/sr-technicions"
                  className={
                    ["/sr-technicions"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-crown"></i>
                  <p>Sr Technicians</p>
                </Link>
              </li>
              <li className="nav-item sideheading">
                <Link
                  to="/jr-technicions"
                  className={
                    ["/jr-technicions"].includes(pathName)
                      ? "nav-link active"
                      : "nav-link"
                  }
                >
                  <i className="nav-icon fas fa-wrench"></i>
                  <p>Jr Technicians</p>
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;
