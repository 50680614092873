import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import CustomModal from "../Modal/CustomModal";
import CreateUser from "../Modal/CreateSrTech";
import { Link } from "react-router-dom";
import { getAllUsers, updateUserStatus } from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { useWebSelector } from "../../redux/selector/web";

const JuniorTechnicions = () => {
  document.title = "Jr Technician";
  const dispatch = useDispatch();
  const webSelector = useWebSelector();
  const { loading } = webSelector;
  const [allUsers, setAllusers] = useState([]);
  const [user, setUser] = useState("");
  const [key, setKey] = useState(Math.random());
  const [modalDetail, setModalDetail] = useState({
    show: false,
    title: "",
    flag: "",
  });

  // update user Status
  const handleUpdateUserStatus = (id, status) => {
    let params = {
      _id: id,
      status: status,
    };
    dispatch(
      updateUserStatus({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            handleGetJrTech();
          }
        },
      })
    );
  };

  // table columns
  const columns = [
    {
      name: "User Id",
      selector: (row) => row.userId,
      sortable: true,
      reorder: true,
    },
    {
      name: "Full Name",
      selector: (row) => `${row.userInfo.fullName}`,
      sortable: true,
      reorder: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      reorder: true,
    },
    {
      name: "Ph No.",
      selector: (row) =>
        row.userInfo.dialCode && row.userInfo.mobile
          ? `+${row.userInfo.dialCode} ${row.userInfo.mobile}`
          : "---",
      sortable: true,
      reorder: true,
    },
    {
      name: "Status",
      selector: (row) => (
        <div className="d-flex align-items-center gap-2 ">
          <div
            className={
              row.status === "Inactive"
                ? "inActive-user active-user"
                : "active-user"
            }
          ></div>
          <p
            className={
              row.status === "Inactive"
                ? "in-active-text mb-0 text-capitalize"
                : "active-text text-capitalize mb-0"
            }
          >
            {row.status}
          </p>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="dropdown filterDropdownChurchList">
          <button
            className="toggle-btn"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v"></i>
          </button>
          <ul className="dropdown-menu">
            <li onClick={() => handleOpenModal("createUser", row)}>
              <Link className="dropdown-item font-14 userText" to="#">
                Edit
              </Link>
            </li>
            {row?.status === "Active" ? (
              <li onClick={() => handleUpdateUserStatus(row?._id, "Inactive")}>
                <Link className="dropdown-item font-14 userText" to="#">
                  InActive
                </Link>
              </li>
            ) : (
              <li onClick={() => handleUpdateUserStatus(row?._id, "Active")}>
                <Link className="dropdown-item font-14 userText" to="#">
                  Active
                </Link>
              </li>
            )}
          </ul>
        </div>
      ),
    },
  ];

  //closeModal
  const handleOnCloseModal = () => {
    setModalDetail({
      show: false,
      title: "",
      flag: "",
    });
    setKey(Math.random());
  };

  //open modal
  const handleOpenModal = (flag, data) => {
    setModalDetail({
      show: true,
      flag: flag,
      type: flag,
    });
    setKey(Math.random());
    setUser(data || "");
  };

  // get all users
  useEffect(() => {
    handleGetJrTech();
  }, []);

  // get all users
  const handleGetJrTech = () => {
    let params = {
      role: "junior_technician",
    };
    dispatch(
      getAllUsers({
        ...params,
        cb(res) {
          if (res?.status === 200) {
            setAllusers(res?.data?.data?.data);
          }
        },
      })
    );
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2 d-flex align-items-center">
              <div className="col-sm-6">
                <h1 className="m-0 headingMain">Jr Technicians</h1>
              </div>
              <div className="col-sm-6">
                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => handleOpenModal("createUser")}
                    className="common-btn"
                  >
                    <i className="fa fa-user-plus me-2"></i>
                    Jr Technician
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section className="content commonBox_  admindash">
          <div className="data-table">
            <DataTable
              columns={columns}
              data={allUsers}
              pagination
              responsive={true}
              progressComponent={
                <div className="spinner-grow " role="status"></div>
              }
              highlightOnHover={true}
              progressPending={loading && !modalDetail.show}
            />
          </div>
        </section>
      </div>
      <CustomModal
        key={key}
        show={modalDetail.show}
        backdrop="static"
        showCloseBtn={false}
        isRightSideModal={true}
        mediumWidth={false}
        className={
          modalDetail.flag === "congratulation"
            ? "commonWidth  customContent"
            : ""
        }
        ids={modalDetail.flag === "createUser" ? "createPassword" : ""}
        child={
          modalDetail.flag === "createUser" ? (
            <CreateUser
              user={user}
              role={"junior_technician"}
              handleGetJrTech={handleGetJrTech}
              close={() => handleOnCloseModal()}
            />
          ) : (
            ""
          )
        }
        header={
          modalDetail.flag === "createUser" ? (
            <>
              <h2 className="profile_modal_heading">
                {user
                  ? "Edit Jr Technician Profile"
                  : "Create New Jr Technician Profile"}
              </h2>
              <p className="modal_cancel"></p>
            </>
          ) : (
            ""
          )
        }
        onCloseModal={() => handleOnCloseModal()}
      />
    </>
  );
};

export default JuniorTechnicions;
