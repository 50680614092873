import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  enterOtp,
  resendEnterOtp,
  onErrorStopLoad,
} from "../../redux/slices/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OTPInput from "react-otp-input";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Images from "../../utilities/images";

const EnterOtp = () => {
  document.title = "Enter OTP";
  const toastId = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const authData = useAuthSelector();
  const { loading } = authData;
  const { state } = location;
  const [isLoading, setisLoading] = useState("");
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30);
  const [isTimer, setIsTimer] = useState(false);

  // Form submit handler
  const handleSubmit = (e, flag) => {
    e.preventDefault();
    if (!otp) {
      showToast("Please enter your OTP");
      return;
    }
    setisLoading(flag);
    let params = {
      otp: otp,
    };
    dispatch(
      enterOtp({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/reset-password", { state: ress });
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // resend OTP
  const handleResendOtp = (e, flag) => {
    e.preventDefault();
    setisLoading(flag);
    let params = {
      type: "forgot",
      email: state?.email,
    };
    dispatch(
      resendEnterOtp({
        ...params,
        cb(res) {
          if (res.status === 200) {
            setOtp("");
            setIsTimer(true);
          }
        },
      })
    );
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // redirect on login page
  useEffect(() => {
    if (!state?.email) {
      navigate("/");
    }
  }, []);

  // handle time
  useEffect(() => {
    if (isTimer) {
      const startTimer =
        timer > 0 &&
        setInterval(() => {
          setTimer(timer - 1);
        }, 1000);
      return () => clearInterval(startTimer);
    }
  }, [timer, isTimer]);

  // stop timer
  useEffect(() => {
    if (timer === 0) {
      setIsTimer(false);
    }
  }, [timer]);

  return (
    <>
      <div className="auth-bg-all">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hey-admin-inner">
              <div className="hey-admin-inner">
                <h1 className="hey-title m-0">Hey👋</h1>
              </div>
              {/* <p className="admin-text-welcome">Welcome</p> */}
              <p className="admin-text">Admin</p>
            </div>
            <div className="col-lg-6">
              <div className="auth-outer-fields">
                <div className="text-center">
                  <img
                    className="mt-5"
                    alt="company-logo"
                    src={Images.brandLogo}
                  />
                </div>
                <div className="form-outer">
                  <div className="auth-heading-form">
                    <h1>Enter OTP</h1>
                    <p>
                      Enter the 4 digit OTP that we just sent your onyour email
                      address.
                    </p>
                  </div>
                  <form
                    className="otpform"
                    onSubmit={(e) => {
                      handleSubmit(e, "submit");
                    }}
                  >
                    <OTPInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      renderInput={(props) => (
                        <input {...props} className="enterOtp" />
                      )}
                    />
                    <p className="mb-3 mt-3 otpheading text-center">
                      Don’t Received{" "}
                      {loading && isLoading === "resend" ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : (
                        <>
                          {isTimer ? (
                            <span className="resendLink">
                              Resend OTP in 00:{timer}
                            </span>
                          ) : (
                            <a
                              onClick={(e) => handleResendOtp(e, "resend")}
                              className="resendLink"
                              href="/auth/otp"
                            >
                              Resend OTP
                            </a>
                          )}
                        </>
                      )}
                    </p>
                    <div>
                      <button
                        disabled={loading}
                        className="auth-submit-button"
                        type="submit"
                      >
                        Continue &nbsp;&nbsp;
                        {loading && isLoading === "submit" && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnterOtp;
