import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { resetPassword, onErrorStopLoad } from "../../redux/slices/auth";
import { useNavigate, Link } from "react-router-dom";
import { useAuthSelector } from "../../redux/selector/auth";
import * as Images from "../../utilities/images";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { passwordValidation } from "../../utilities/helper";

const ResetPassword = () => {
  document.title = "Reset Password";
  const toastId = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authData = useAuthSelector();
  const location = useLocation();
  const { state } = location;
  const { loading } = authData;
  const [password, setPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [confirmTogglePassword, setConfirmTogglePassword] = useState(false);

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  // Form submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      showToast("Please enter your password");
      return;
    } else if (!passwordValidation(password)) {
      showToast("Password must be at least 8 characters long and contain only letters and numbers.");
      return;
    } else if (!confirmPassword) {
      showToast("Please enter confirm password");
      return;
    } else if (password !== confirmPassword) {
      showToast("Passwords do not match");
      return;
    }
    let params = {
      new_password: password,
      confirm_password: confirmPassword,
      user_id: state?.data?.data?.user_id,
    };
    dispatch(
      resetPassword({
        ...params,
        cb(ress) {
          if (ress.status === 200) {
            navigate("/");
          }
        },
      })
    );
  };

  // stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // redirect on login page
  useEffect(() => {
    if (!state?.data?.data?.user_id) {
      navigate("/");
    }
  }, []);

  // toggle password confirmation
  const handleTogglePassword = (flag) => {
    if (flag === "password") {
      setTogglePassword(!togglePassword);
    } else {
      setConfirmTogglePassword(!confirmTogglePassword);
    }
  };

  return (
    <>
      <div className="auth-bg-all">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hey-admin-inner">
              <div className="hey-admin-inner">
                <h1 className="hey-title m-0">Hey👋</h1>
              </div>
              {/* <p className="admin-text-welcome">Welcome</p> */}
              <p className="admin-text">Admin</p>
            </div>
            <div className="col-lg-6">
              <div className="auth-outer-fields">
                <div className="text-center">
                  <img
                    className="mt-5"
                    alt="company-logo"
                    src={Images.brandLogo}
                  />
                </div>
                <div className="form-outer">
                  <div className="auth-heading-form">
                    <h1>Recover Password</h1>
                    <p>Create your new password here.</p>
                  </div>
                  <form
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                  >
                    <div className="input-container text-filed-outer">
                      <input
                        type={togglePassword ? "text" : "password"}
                        className="input-fileds"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPassword(e.target.value);
                        }}
                      />
                      <img
                        onClick={() => handleTogglePassword("password")}
                        className="input-images-password"
                        src={
                          togglePassword ? Images.hidePassword : Images.showeye
                        }
                        alt="lock"
                      />
                    </div>
                    <div className="input-container text-filed-outer mt-3">
                      <input
                        type={confirmTogglePassword ? "text" : "password"}
                        className="input-fileds"
                        placeholder="Confirm Password"
                        value={confirmPassword}
                        onChange={(e) => {
                          setconfirmPassword(e.target.value);
                        }}
                      />
                      <img
                        onClick={() => handleTogglePassword("cpassword")}
                        className="input-images-password"
                        src={
                          confirmTogglePassword ? Images.hidePassword : Images.showeye
                        }
                        alt="Message"
                      />
                    </div>

                    <div className="my-3 text-end">
                      {/* <Link to="/" className="forgot-heading">
                        Login
                      </Link> */}
                    </div>
                    <div>
                      <button
                        disabled={loading}
                        className="auth-submit-button"
                        type="submit"
                      >
                        Submit &nbsp;&nbsp;
                        {loading && (
                          <span className="spinner-border spinner-border-sm"></span>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
