const ApiPath = {
  AuthApiPath: {
    ADMIN_LOGIN: "/users/login",
    FORGOT_PASSWORD: "/users/forgot-password",
    RESET_PASSWORD: "/users/reset-password",
    LOGOUT_ADMIN: "/users/logOut",
    ENTER_OTP: "/users/reset-password-otp-verify",
    RSEND_ENTER_OTP: "/users/resend",
    USER_CHEF_LIST: "/users/all",
  },
  webApiPath: {
    DASHBOARD_DATA: "/users/dashboard",
    ALL_TECHNICIANS: "/users",
    ADD_USER: "/users",
    UPDATE_USER: "/users/update-user",
    UPDATE_USER_STATUS:"/users/update-status"
  },
};

export default ApiPath;
