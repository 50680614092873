import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthSelector } from "../redux/selector/auth";

const ProtectRoute = ({ children }) => {
  const authData = useAuthSelector();
  const { userInfo, isLoggedIn } = authData;

  return userInfo && Object.keys(userInfo)?.length > 0 && isLoggedIn ? (
    children
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectRoute;
