import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { addUsers, updateUsers, onErrorStopLoad } from "../../redux/slices/web";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useWebSelector } from "../../redux/selector/web";
import { blockInvalidChar } from "../../utilities/helper";
import * as Images from "../../utilities/images";

const CreateSrTech = ({
  close,
  user,
  role,
  handleGetJrTech,
  handleGetSrTech,
}) => {
  const dispatch = useDispatch();
  const toastId = useRef(null);
  const webData = useWebSelector();
  const { loading } = webData;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
  });

  // get number or dialcode
  const onChangePhoneNumber = (value, data) => {
    setDialCode(data.dialCode);
    let validNumber = value.slice(data.dialCode.length);
    setPhoneNumber(validNumber);
  };

  // get formData
  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // show only one toast at one time
  const showToast = (msg) => {
    if (!toast.isActive(toastId.current)) {
      toastId.current = toast.error(msg);
    }
  };

  //stop loader on page load
  useEffect(() => {
    dispatch(onErrorStopLoad());
  }, [dispatch]);

  // toggle password confirmation
  const handleTogglePassword = () => {
    setTogglePassword(!togglePassword);
  };

  // add users
  const handleAddUsers = () => {
    if (!formData?.fullName?.trim()) {
      showToast("Please enter full name");
      return;
    } else if (!formData?.password?.trim() && !user) {
      showToast("Please enter password");
      return;
    } else if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(
        formData.password
      ) &&
      !user
    ) {
      showToast(
        "Password must be at least 8 characters long with 1 capital letter, 1 number and 1 special character"
      );
      return;
    } else if (formData.fullName.length < 2) {
      showToast("Full name should of atleast 2 characters or more");
      return;
    } else if (!formData.email) {
      showToast("Please enter email");
      return;
    } else if (
      formData.email &&
      !/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/.test(
        formData.email
      )
    ) {
      showToast("Please enter valid email address");
      return;
    } else if (!phoneNumber) {
      showToast("Please enter phone number");
      return;
    } else if (phoneNumber && !/^[^\D\W_]{8,15}$/.test(phoneNumber)) {
      showToast("Please enter a valid phone number");
      return;
    }

    let params = {
      fullName: formData.fullName,
      email: formData.email,
      password: formData.password,
      role: role,
      mobile: phoneNumber,
      dialCode: dialCode,
    };
    if (user) {
      params._id = user._id;
      delete params.role;
    }
    if (user) {
      dispatch(
        updateUsers({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              close();
              if (role === "technician") {
                handleGetSrTech();
              } else {
                handleGetJrTech();
              }
            }
          },
        })
      );
    } else {
      dispatch(
        addUsers({
          ...params,
          cb(res) {
            if (res?.status === 200) {
              close();
              if (role === "technician") {
                handleGetSrTech();
              } else {
                handleGetJrTech();
              }
            }
          },
        })
      );
    }
  };

  // get individual data
  useEffect(() => {
    if (user) {
      setFormData({
        fullName: user.userInfo.fullName,
        email: user.email,
      });
      setPhoneNumber(user.userInfo.mobile);
      setDialCode(user.userInfo.dialCode);
    }
  }, []);

  return (
    <>
      <div className="create-user-outer">
        <div className="mb-2 text-filed-outer">
          <label className="modal-labels">Full Name</label>
          <input
            type="text"
            className="input-fields-modals"
            placeholder="Full Name"
            name="fullName"
            maxLength={25}
            onKeyDown={blockInvalidChar}
            value={formData.fullName}
            onChange={handleChange}
          />
        </div>
        <div className="mb-2 text-filed-outer">
          <label className="modal-labels">Email</label>
          <input
            type="email"
            value={formData.email}
            className="input-fields-modals"
            placeholder="Email"
            name="email"
            disabled={user}
            onChange={handleChange}
          />
        </div>

        {!user && (
          <div className="mb-2 text-filed-outer">
            <label className="modal-labels">Password</label>
            <div className="position-relative">
            <input
              type={togglePassword ? "text" : "password"}
              value={formData.password}
              className="input-fields-modals"
              placeholder="Password"
              name="password"
              disabled={user}
              onChange={handleChange}
            />
            <img
              onClick={handleTogglePassword}
              className="input-images-password-modal"
              src={togglePassword ? Images.hidePassword : Images.showeye}
              alt="eye-icon-lock"
            />
            </div>
          </div>
        )}

        <div className="mb-2 text-filed-outer">
          <label className="modal-labels">Phone Number</label>
          <PhoneInput
            className="input-field-phone"
            country={"us"}
            value={dialCode + phoneNumber}
            onChange={(value, data, event, formattedValue) =>
              onChangePhoneNumber(value, data, event, formattedValue)
            }
          />
        </div>
        <div className="mt-4 modals-btn">
          <button
            disabled={loading}
            type="button"
            onClick={handleAddUsers}
            className="create-btn"
          >
            {user ? "Save" : "Create"}
            {loading && (
              <span className="spinner-border spinner-border-sm ms-1"></span>
            )}
          </button>
          <button onClick={close} className="cancel-btn">
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default CreateSrTech;
