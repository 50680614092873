import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthSelector } from "../redux/selector/auth";

const PublicRoute = ({ children }) => {
  const authData = useAuthSelector();
  const { userInfo, isLoggedIn } = authData;

  if (!userInfo && !isLoggedIn) {
    return children;
  } else if (userInfo && Object.keys(userInfo)?.length > 0 && isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }
};

export default PublicRoute;
