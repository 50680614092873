export const onlyCharactersRegex = /^[a-zA-Z\s]+$/;
export const letterRegex = /[a-zA-Z]/;
export const numberRegex = /[0-9]/;
export const specialCharacterRegex = /[!@#$%^&*()_+{}|:"<>?/.,;`~'[\]\\\-]/;

export const blockInvalidChar = (e) => {
    if (!onlyCharactersRegex.test(e.key)) e.preventDefault()
}

export const passwordValidation = (password) => {
    let isValid = false
    if (letterRegex.test(password) && numberRegex.test(password) && specialCharacterRegex.test(password) && password.length >= 8) isValid = true
    return isValid
}